import React, { useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import useAPI from '../hooks/useAPI'
import Paginator, { PageEvent } from '../components/Paginator'
import { ListResults, SimpleProductResult } from '../types/backend'
import { NavLink } from 'react-router-dom'
import useQueryParams from '../hooks/useQueryParams'
import styled from 'styled-components'
import { transformNaturalNumber } from '../utils/transformers'

const DEFAULT_OFFSET = 0
const DEFAULT_LIMIT = 10

const ProductCategoryPageContainer = styled.div`
  position: relative;
`

const StyledResultListItem = styled.ul`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px;
  color: black;
  height: 120px;

  img {
    height: 100%;
    mix-blend-mode: multiply;
  }

  &:hover {
    background-color: #ddd;
  }
`

const ProductCategoryPage = () => {
  const { '*': category } = useParams()
  const { offset, limit, updateParams } = useQueryParams(
    {
      offset: DEFAULT_OFFSET,
      limit: DEFAULT_LIMIT,
    },
    {
      offset: value => transformNaturalNumber(value, DEFAULT_OFFSET),
      limit: value => transformNaturalNumber(value, DEFAULT_LIMIT),
    },
  )

  const { data, updateConfig } = useAPI<ListResults<SimpleProductResult>>({
    url: `products/categories/${category}`,
    params: { limit, offset },
  })

  useEffect(() => {
    updateConfig({ params: { limit, offset } })
  }, [category, limit, offset, updateConfig])

  const handlePageEvent = useCallback(
    ({ activePage, itemsPerPage }: PageEvent) =>
      updateParams({
        offset: (activePage - 1) * itemsPerPage,
        limit: itemsPerPage,
      }),
    [updateParams],
  )

  if (data?.count === 0) {
    return <h1>Products in Category {category} not found</h1>
  }

  return (
    <ProductCategoryPageContainer>
      <h1>Products in Category {category}</h1>
      {data?.results.map((result, i) => (
        <NavLink
          key={i}
          to={{ pathname: '/product/' + encodeURIComponent(result.model) }}
        >
          <StyledResultListItem>
            <span>{result.name}</span>
            <img src={result.image} />
          </StyledResultListItem>
        </NavLink>
      ))}
      {data && (
        <Paginator
          onPageEvent={handlePageEvent}
          defaultItemsPerPage={limit}
          itemCount={data?.count}
        />
      )}
    </ProductCategoryPageContainer>
  )
}

export default ProductCategoryPage
