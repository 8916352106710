import React, { useEffect } from 'react'
import { NavLink, useParams } from 'react-router-dom'
import useAPI from '../hooks/useAPI'
import { ProductDetails } from '../types/backend'
import styled from 'styled-components'
import PriceHistoryGraph from '../components/PriceHistoryGraph'

const StyledProductImage = styled.img`
  width: 300px;
`

const StyledSpecsTable = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`

const StyledProductLatestPriceTable = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  margin: 50px 20px;
`

const ProductPage = () => {
  const { '*': model } = useParams()
  const { data, updateConfig } = useAPI<ProductDetails>()

  useEffect(() => updateConfig({ url: `product/${model}/details/` }), [model])

  if (!data) {
    return <h1>Product not found</h1>
  }

  const { name, image, specifications, latest_price_data, categories } = data
  return (
    <>
      <div>
        {categories.map(item => (
          <NavLink
            style={{
              margin: '5px',
              padding: '10px',
              backgroundColor: '#f0f0f0',
            }}
            key={item}
            to={{ pathname: '/category/' + encodeURIComponent(item) }}
          >
            <span>{item}</span>
          </NavLink>
        ))}
      </div>

      <h1>
        Product - {model} - {name}
      </h1>
      <StyledProductImage src={image} />

      <PriceHistoryGraph model={model} />

      <StyledProductLatestPriceTable>
        {latest_price_data.map(({ source, price }, i) => (
          <React.Fragment key={i}>
            <span>{source}</span>
            <span>${price}</span>
          </React.Fragment>
        ))}
      </StyledProductLatestPriceTable>

      <StyledSpecsTable>
        {Object.entries(specifications)
          .sort()
          .map(([key, value], i) => (
            <React.Fragment key={i}>
              <span>{key}</span>
              <span>{Array.isArray(value) ? value.join(', ') : value}</span>
            </React.Fragment>
          ))}
      </StyledSpecsTable>
    </>
  )
}

export default ProductPage
